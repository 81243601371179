import { ChevronDownIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "@prime/ui/src/button";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "../../lib/css";

type Step = {
  title: string;
  content: ({
    accountingPlatform,
  }: {
    accountingPlatform?: string;
  }) => string | JSX.Element;
  youtube?: string;
  sup?: string;
};

const STEPS: Step[] = [
  {
    title: "Applying",
    content: ({ accountingPlatform }) =>
      `In order to apply, you must ${accountingPlatform ? `connect your ${accountingPlatform} account` : "have been prequalified and receive an invitation from us"}. The loan application is simple and should take no more than 10 minutes to complete.`,
  },
  {
    title: "Getting Approved",
    content: () =>
      "After the application is completed, a decision or request for more information should be made within 1-3 business days.",
  },
  {
    title: "Credit Scores",
    content: () => (
      <>
        A soft credit check of the applicant will be required for approval. If
        you have a security freeze on your personal credit score, please review
        how to manage a credit freeze{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.equifax.com/personal/credit-report-services/credit-freeze/"
          className="underline"
        >
          here
        </a>
        .  Applying will not have any impact on your credit score.
      </>
    ),
  },
  {
    title: "Fixed Fee",
    sup: "3",
    content: () =>
      "Your loan payments are structured to be consistent throughout the loan term. The exact payment amount will depend on how much you borrow, the term length and either the applicable APR or Fixed Fee. Additional fees may be assessed on overdue amounts.",
  },
  {
    title: "Funding",

    sup: "1",
    content: () =>
      "Your lender is the financial institution that provides the funds for your business loan. All loans are issued by Lead Bank.",
  },
  {
    title: "Payments",
    content: () =>
      "Payments will be automatically withdrawn weekly from the business checking account that you connect as part of your loan application.",
  },
  {
    title: "Servicing and Support",
    sup: "2",
    content: () =>
      "Prime Financial Technologies, Inc. (”Prime”) is the servicer of your loan. Prime is responsible for administering and managing your loan on behalf of the lender. Prime will handle tasks such as processing payments, providing customer service, and handling all loan-related inquiries and requests.  Please reach out to support@pop.loans for any and all loan related questions.",
  },
];

const stepVariants = cva(
  "flex cursor-pointer flex-row items-center justify-start gap-[32px] self-stretch border-t-[1px] border-solid border-gray-200 border-opacity-25 px-0 py-7",
  {
    variants: {
      variant: {
        default: "text-white hover:text-gray-300",
        xero: "border-gray-400",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export function AccordionStep({
  opened = false,
  title,
  content,
  onOpen = () => {},
  onNext = () => {},
  showNext = true,
  youtube = "",
  sup = "",
  variant = "default",
}: {
  opened?: boolean;
  showNext?: boolean;
  title: string;
  content: string | JSX.Element;
  onOpen: () => void;
  onNext?: () => void;
  youtube?: string;
  sup?: string;
} & VariantProps<typeof stepVariants>) {
  return (
    <div
      className={"flex flex-col items-center overflow-hidden"}
      style={{
        maxHeight: opened ? "1000px" : "82px",
      }}
    >
      <div onClick={onOpen} className={cn(stepVariants({ variant }))}>
        <div
          className={`${opened ? "text-xl" : ""} h-[25px] flex-1 text-left font-medium`}
        >
          {title}
          {sup ? <sup className="text-xs"> {sup}</sup> : null}
        </div>
        {!opened ? <ChevronDownIcon size={20} /> : null}
      </div>
      {opened && youtube ? (
        <iframe
          className="my-7 aspect-video w-full rounded-md"
          src={youtube}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      ) : null}
      <div className="relative self-stretch leading-[150%] opacity-[0.8]">
        {content}
      </div>
      {showNext ? (
        <Button
          variant={variant === "default" ? "outline" : variant}
          size={variant === "default" ? undefined : variant}
          onClick={onNext}
          className="my-7 h-12 w-full border-2 border-white px-4 py-0 text-sm font-light leading-[150%] text-white opacity-[0.8]"
        >
          Next
        </Button>
      ) : null}
    </div>
  );
}

export function Guide({
  className,
  variant,
  innerClassName,
  accountingPlatform,
}: {
  className?: string;
  innerClassName?: string;
  accountingPlatform?: string;
} & VariantProps<typeof stepVariants>) {
  const [openedStep, setOpenedStep] = useState<number>(0);
  return (
    <div
      id="guide"
      className={cn(
        "text-txt-inverted flex w-full flex-col items-center justify-start bg-[hsl(var(--brand))] p-12 text-left text-[36px] md:rounded-3xl md:p-[60px] lg:justify-center lg:text-[48px]",
        className
      )}
    >
      <div
        className={cn(
          "flex w-full flex-col items-center justify-center gap-[48px] lg:flex-row lg:items-start",
          innerClassName
        )}
      >
        <div className="flex max-w-[480px] flex-1 flex-col items-center justify-center lg:items-start">
          <div className="flex flex-col items-center justify-center gap-[12px] lg:items-start lg:justify-start">
            <h3 className="w-full font-medium leading-[100%]">
              Everything You
              <br />
              Need to Know
            </h3>
            <div className="w-full text-xl leading-[150%]">
              Find answers to some frequently asked questions.
              <br />
              Please get in touch if you can't find the information you're
              looking for.
            </div>
          </div>
        </div>
        <div className="flex max-w-[480px] flex-1 flex-col items-center justify-start text-lg lg:text-xl">
          {STEPS.map((step, index) => {
            return (
              <AccordionStep
                key={index}
                variant={variant}
                opened={openedStep === index}
                title={step.title}
                content={step.content({ accountingPlatform })}
                youtube={step.youtube}
                onOpen={() => setOpenedStep(index)}
                onNext={() => setOpenedStep(index + 1)}
                showNext={index < STEPS.length - 1}
                sup={step.sup}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
