import React, { createContext, useContext, useMemo, useState } from "react";

import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";

type AuthUser = {
  sub: string;
  email?: string;
  phoneNumber?: string;
  name: string;
};

type RequestCodeRequest = {
  email?: string;
  phoneNumber?: string;
};

type ExchangeCodeRequest = {
  email?: string;
  phoneNumber?: string;
  code?: string;
  refreshToken?: string;
  oauthCode?: string;
  redirectUri?: string;
};

type Tokens = {
  access_token: string;
  refresh_token?: string;
  id_token: string;
  token_type: string;
  expires_in: number;
};

type GetConnectionRequest = {
  connection: string;
  allScopes?: boolean;
  state?: Record<string, unknown>;
};

export type AuthContextValue = {
  // Replicating Auth0 SDK context
  isAuthenticated: boolean;
  user: AuthUser | null;
  logout: ({ returnTo }: { returnTo?: string }) => void;
  loginWithRedirect: ({
    code,
    returnTo,
    returnToSearch,
  }: {
    code: string;
    returnTo: string;
    returnToSearch?: Record<string, unknown>;
  }) => Promise<void>; // Will only be used for oauth flows

  // New Auth Endpoints
  requestCode: ({
    email,
    phoneNumber,
  }: RequestCodeRequest) => Promise<{ message: string }>;
  exchangeCode: ({
    email,
    phoneNumber,
    code,
    refreshToken,
  }: ExchangeCodeRequest) => Promise<Tokens>;
  signInWith: ({
    connection,
    allScopes,
    state,
  }: GetConnectionRequest) => Promise<void>;
};

type APIContextValue = {
  storage: Storage;
  lendingService: AxiosInstance;
  applyBackend: AxiosInstance;
  auth: AuthContextValue;
};

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  requiresAuth?: boolean;
}

const APIContext = createContext<APIContextValue | null>(null);

const defaultRedirectUri = `${window.location.origin}/authorize`;

const hasLocalStorage = (() => {
  try {
    localStorage.setItem("_test", "_test");
    localStorage.removeItem("_test");
    return true;
  } catch (e) {
    return false;
  }
})();

const storage = hasLocalStorage ? localStorage : sessionStorage;

const storeTokens = (tokens: Tokens) => {
  storage.setItem("accessToken", tokens.access_token);
  storage.setItem("refreshToken", tokens?.refresh_token || "");
  storage.setItem("idToken", tokens.id_token);
  storage.setItem("tokenType", tokens.token_type);
};

const purgeTokens = () => {
  storage.removeItem("accessToken");
  storage.removeItem("refreshToken");
  storage.removeItem("idToken");
  storage.removeItem("tokenType");
};

const isTokenExpired = () => {
  const accessToken = storage.getItem("accessToken");
  if (!accessToken) {
    return true;
  }
  const decoded = jwtDecode(accessToken) as { exp: number };
  return decoded.exp < Date.now() / 1000;
};

const getUserDetails = () => {
  const idToken = storage.getItem("idToken");
  if (!idToken) {
    return null;
  }
  const decoded = jwtDecode(idToken) as AuthUser;
  return decoded;
};

const requestCode = async ({
  email,
  phoneNumber,
}: RequestCodeRequest): Promise<{
  message: string;
}> => {
  const { VITE_APPLY_BACKEND_URL: applyBackendUrl } = import.meta.env;

  const response = await axios.post<{
    message: string;
  }>(`${applyBackendUrl}/auth/request-code`, {
    email,
    phone_number: phoneNumber,
  });

  return response.data;
};

const exchangeCode = async ({
  email,
  phoneNumber,
  code,
  refreshToken,
  oauthCode,
  redirectUri = defaultRedirectUri,
}: ExchangeCodeRequest) => {
  const { VITE_APPLY_BACKEND_URL: applyBackendUrl } = import.meta.env;
  const response = await axios.post<Tokens>(
    `${applyBackendUrl}/auth/exchange-code`,
    {
      phone_number: phoneNumber,
      email: !phoneNumber ? email : undefined,
      refresh_token: refreshToken,
      code,
      oauth_code: oauthCode,
      redirect_uri: !oauthCode ? undefined : redirectUri,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  storeTokens(response.data);

  return response.data;
};

const signInWith = async ({
  connection,
  allScopes = false,
  state,
}: GetConnectionRequest) => {
  const { VITE_APPLY_BACKEND_URL: applyBackendUrl } = import.meta.env;

  const params: {
    redirect_uri: string;
    use_all_scopes: string;
    state?: string;
  } = {
    redirect_uri: defaultRedirectUri,
    use_all_scopes: String(allScopes),
  };

  if (state) {
    params.state = JSON.stringify(state);
  }

  const queryString = new URLSearchParams(params).toString();

  const response = await axios.get<string>(
    `${applyBackendUrl}/auth/oauth/${connection}?${queryString}`
  );

  window.location.href = response.data;
};

const refreshIfExpired = async () => {
  if (isTokenExpired()) {
    const refreshToken = storage.getItem("refreshToken");
    if (refreshToken) {
      await exchangeCode({ refreshToken }).catch(purgeTokens);
    } else {
      purgeTokens();
    }
  }
};

const createAxiosInstance = (url: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: url,
  });

  instance.interceptors.request.use(
    async (config: CustomAxiosRequestConfig) => {
      const { requiresAuth = true } = config;

      if (!requiresAuth) {
        return config;
      }

      await refreshIfExpired();

      const accessToken = storage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        // Redirect to login if user is not authenticated
        window.location.href = "/";
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

export default function APIProvider(props: { children?: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(!isTokenExpired());
  const [authUser, setAuthUser] = useState(getUserDetails());

  const {
    VITE_APPLY_API_URL: lendingServiceUrl,
    VITE_APPLY_BACKEND_URL: applyBackendUrl,
  } = import.meta.env;

  const lendingService = useMemo(
    () => createAxiosInstance(lendingServiceUrl),
    [lendingServiceUrl]
  );

  const applyBackend = useMemo(
    () => createAxiosInstance(applyBackendUrl),
    [applyBackendUrl]
  );

  return (
    <APIContext.Provider
      {...props}
      value={{
        storage,
        lendingService,
        applyBackend,
        auth: {
          isAuthenticated,
          user: authUser,
          logout: ({ returnTo }) => {
            purgeTokens();
            setIsAuthenticated(false);
            setAuthUser(null);
            if (returnTo) {
              window.location.href = returnTo;
            }
          },
          loginWithRedirect: ({
            code,
            returnTo,
            returnToSearch,
          }: {
            code: string;
            returnTo: string;
            returnToSearch?: Record<string, unknown>;
          }) => {
            return exchangeCode({ oauthCode: code })
              .then(() => {
                setIsAuthenticated(true);
                setAuthUser(getUserDetails());
                const url = new URL(`${window.location.origin}${returnTo}`);
                if (returnToSearch) {
                  Object.entries(returnToSearch).forEach(([key, value]) => {
                    url.searchParams.append(key, value as string);
                  });
                }
                window.location.href = url.toString();
              })
              .catch((error) => {
                setIsAuthenticated(false);
                setAuthUser(null);
                throw error;
              });
          },
          // Auth endpoints
          requestCode,
          exchangeCode: (params: ExchangeCodeRequest): Promise<Tokens> =>
            exchangeCode(params)
              .then((tokens) => {
                setIsAuthenticated(true);
                setAuthUser(getUserDetails());
                return tokens;
              })
              .catch((error) => {
                setIsAuthenticated(false);
                setAuthUser(null);
                throw error;
              }),
          signInWith,
        },
      }}
    />
  );
}

export function useAPI() {
  const context = useContext(APIContext);

  if (!context) {
    throw new Error(
      "You are trying to access the useAPI() hook outside of the ApiContextProvider"
    );
  }
  return context;
}
